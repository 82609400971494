import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixCheckbox from '@1024pix/pix-ui/components/pix-checkbox';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import Card from 'pix-admin/components/card';
import LinkToCurrentTargetProfile from '../common/link-to-current-target-profile';
import Badges from './badges/index';
import TargetProfileSelector from './target-profile-selector';
const DEFAULT_BADGE_LEVEL = '1';
export default class AttachBadges extends Component {
    @service
    pixToast;
    @service
    router;
    @service
    store;
    @tracked
    isSubmitDisabled = true;
    @tracked
    isSubmitting = false;
    @tracked
    selectedTargetProfile;
    #notifyOrganizations = true;
    #targetProfileBadges = new Map();
    get hasExternalJury() {
        return this.args.complementaryCertification.hasExternalJury;
    }
    @action
    async onError(errorMessage1) {
        if (errorMessage1) {
            this.pixToast.sendErrorNotification({
                message: errorMessage1
            });
        }
    }
    @action
    async onSelection(selectedAttachableTargetProfile1) {
        if (selectedAttachableTargetProfile1) {
            this.selectedTargetProfile = selectedAttachableTargetProfile1;
            this.#targetProfileBadges = new Map();
            this.isSubmitDisabled = false;
        }
    }
    @action
    onReset() {
        this.selectedTargetProfile = undefined;
        this.#targetProfileBadges = new Map();
        this.#notifyOrganizations = true;
        this.isSubmitDisabled = true;
        this.isSubmitting = false;
    }
    @action
    onBadgeUpdated({ update: { badgeId: badgeId1, fieldName: fieldName1, fieldValue: fieldValue1 } }) {
        this.#updateBadge({
            badgeId: badgeId1,
            fieldName: fieldName1,
            fieldValue: fieldValue1
        });
    }
    @action
    onNotificationUpdated({ target: target1 }) {
        this.#notifyOrganizations = target1.checked;
    }
    @action
    async onCancel() {
        this.router.transitionTo('authenticated.complementary-certifications.complementary-certification.details');
    }
    @action
    async onSubmit(event1) {
        event1.preventDefault();
        this.isSubmitting = true;
        try {
            const complementaryCertification1 = this.args.complementaryCertification;
            const complementaryCertificationBadges1 = this.store.peekAll('complementary-certification-badge').toArray();
            complementaryCertificationBadges1.forEach((complementaryCertificationBadge1)=>{
                complementaryCertification1.complementaryCertificationBadges.removeObject(complementaryCertificationBadge1);
            });
            this.#targetProfileBadges.forEach((badge1, badgeId1)=>{
                const aBadge1 = this.store.createRecord('complementary-certification-badge', {
                    complementaryCertification: complementaryCertification1,
                    badgeId: badgeId1,
                    level: badge1.level ?? DEFAULT_BADGE_LEVEL,
                    imageUrl: badge1['certificate-image'],
                    label: badge1['certificate-label'],
                    certificateMessage: badge1['certificate-message'],
                    temporaryCertificateMessage: badge1['certificate-temporary-message'],
                    stickerUrl: badge1['certificate-sticker'],
                    minimumEarnedPix: badge1['minimum-earned-pix']
                });
                complementaryCertification1.complementaryCertificationBadges.pushObject(aBadge1);
            });
            await complementaryCertification1.save({
                adapterOptions: {
                    attachBadges: true,
                    targetProfileId: this.args.currentTargetProfile?.id,
                    notifyOrganizations: this.#notifyOrganizations
                }
            });
            this.router.transitionTo('authenticated.complementary-certifications.complementary-certification.details');
            this.pixToast.sendSuccessNotification({
                message: `Profil cible rattaché à la certification ${complementaryCertification1.label} mis à jour avec succès !`
            });
        } catch (error1) {
            console.error({
                error: error1
            });
            await this.onError("Une erreur est survenue lors de l'enregistrement du profil cible.");
        } finally{
            this.isSubmitting = false;
        }
    }
    #updateBadge({ badgeId: badgeId1, fieldName: fieldName1, fieldValue: fieldValue1 }) {
        const currentBadge1 = this.#targetProfileBadges.get(badgeId1);
        this.#targetProfileBadges.set(badgeId1, {
            ...currentBadge1,
            [fieldName1]: fieldValue1
        });
    }
    static{
        template(`
    <div class="page-section attach-target-profile">
      <h1 class="attach-target-profile__header">
        Rattacher un nouveau profil cible à la certification
        {{@complementaryCertification.label}}
      </h1>
      {{#if @currentTargetProfile}}
        <LinkToCurrentTargetProfile @model={{@currentTargetProfile}} />
      {{/if}}
      <form class="form" {{on "submit" this.onSubmit}}>
        <Card class="attach-target-profile__card" @title="1. Renseigner le nouveau profil cible à rattacher">
          <TargetProfileSelector
            @onError={{this.onError}}
            @onSelection={{this.onSelection}}
            @onChange={{this.onReset}}
          />
        </Card>

        {{#if this.selectedTargetProfile}}
          <Card
            class="attach-target-profile__card attach-target-profile__card-badges"
            @title="2. Complétez les informations des résultats thématiques"
          >
            <Badges
              @targetProfile={{this.selectedTargetProfile}}
              @onError={{this.onError}}
              @onBadgeUpdated={{this.onBadgeUpdated}}
              @hasExternalJury={{this.hasExternalJury}}
            />
          </Card>
          {{#if @currentTargetProfile}}

            <div class="badge-edit-form__field attach-target-profile__notification">
              <PixCheckbox
                class="badge-edit-form__control attach-target-profile__notification__checkbox"
                @checked="false"
                {{on "change" this.onNotificationUpdated}}
              >
                <:label>Notifier les organisations avec une campagne basée sur l’ancien PC</:label>
              </PixCheckbox>

              <PixTooltip @position="top-left" @isLight={{true}} @isWide={{true}}>
                <:triggerElement>
                  <FaIcon @icon="circle-question" tabindex="0" />
                </:triggerElement>
                <:tooltip>
                  Un email sera envoyé à chaque membre de l'organisation
                </:tooltip>
              </PixTooltip>
            </div>
          {{/if}}
        {{/if}}

        <div class="attach-target-profile__actions">
          <PixButton
            @type="submit"
            @size="large"
            @isDisabled={{this.isSubmitDisabled}}
            aria-disabled={{this.isSubmitDisabled}}
            @isLoading={{this.isSubmitting}}
          >
            Rattacher le profil cible
          </PixButton>
          <PixButton @size="large" @variant="secondary" @triggerAction={{this.onCancel}}>
            {{t "common.actions.cancel"}}
          </PixButton>
        </div>
      </form>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
