import { template } from "@ember/template-compiler";
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import AdministrationBlockLayout from '../block-layout';
export default class OrganizationsImport extends Component {
    @service
    intl;
    @service
    pixToast;
    @service
    router;
    @service
    store;
    @action
    async importOrganizations(files1) {
        const adapter1 = this.store.adapterFor('organizations-import');
        try {
            await adapter1.addOrganizationsCsv(files1);
            this.pixToast.sendSuccessNotification({
                message: this.intl.t('components.administration.organizations-import.notifications.success')
            });
        } catch (errorResponse1) {
            const errors1 = errorResponse1.errors;
            if (!errors1) {
                return this.pixToast.sendErrorNotification({
                    message: this.intl.t('common.notifications.generic-error')
                });
            }
            errors1.forEach((error1)=>{
                switch(error1.code){
                    case 'MISSING_REQUIRED_FIELD_NAMES':
                        this.pixToast.sendErrorNotification({
                            message: `${error1.meta}`
                        });
                        break;
                    default:
                        this.pixToast.sendErrorNotification({
                            message: error1.detail
                        });
                }
            });
        } finally{
            this.isLoading = false;
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "components.administration.organizations-import.title"}}
      @description={{t "components.administration.organizations-import.description"}}
    >
      <PixButtonUpload @id="orga-file-upload" @onChange={{this.importOrganizations}} @variant="secondary" accept=".csv">
        {{t "components.administration.organizations-import.upload-button"}}
      </PixButtonUpload>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
