import { template } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import { LinkTo } from '@ember/routing';
import { service } from '@ember/service';
import Component from '@glimmer/component';
export default class Breadcrumb extends Component {
    @service
    intl;
    @service
    router;
    get breadcrumb() {
        const crumbs1 = this.router.currentRouteName.split('.');
        const { localName: localName1, parent: parent1 } = this.router.currentRoute;
        const breadcrumbs1 = [];
        if (localName1 !== 'index') {
            const parentCrumbs1 = parent1.name.split('.');
            // on enlève 'authenticated' pour aller chercher la traduction du parent
            parentCrumbs1.shift();
            breadcrumbs1.push({
                path: parent1.name,
                label: this.intl.t(`components.${parentCrumbs1.join('.')}.title`)
            });
        }
        // on enlève 'authenticated' et 'index' pour aller chercher la traduction du titre de la page courante
        crumbs1.shift();
        if (localName1 === 'index') {
            crumbs1.pop();
        }
        breadcrumbs1.push({
            path: null,
            label: this.args.title ?? this.intl.t(`components.${crumbs1.join('.')}.title`)
        });
        return breadcrumbs1;
    }
    static{
        template(`
    <nav class="breadcrumb">
      <ol>
        {{#each this.breadcrumb as |crumb|}}
          {{#if crumb.path}}
            <li>
              <LinkTo @route={{crumb.path}}>{{crumb.label}}</LinkTo>
            </li>
            <PixIcon @name="chevronRight" />
          {{else}}
            <li>
              <h1>{{crumb.label}}</h1>
            </li>
          {{/if}}
        {{/each}}
      </ol>
    </nav>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
