import { template } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import PixTooltip from '@1024pix/pix-ui/components/pix-tooltip';
import { concat } from '@ember/helper';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { pageTitle } from 'ember-page-title';
import partition from 'lodash/partition';
import ENV from 'pix-admin/config/environment';
export default class CertifiedProfile extends Component {
    @tracked
    certifiedAreas = [];
    @tracked
    certifiedCompetences = [];
    @tracked
    certifiedTubes = [];
    @tracked
    certifiedSkills = [];
    constructor(){
        super(...arguments);
        this.args.certifiedProfile.certifiedAreas.then((certifiedAreas1)=>{
            this.certifiedAreas = certifiedAreas1;
        });
        this.args.certifiedProfile.certifiedCompetences.then((certifiedCompetences1)=>{
            this.certifiedCompetences = certifiedCompetences1;
        });
        this.args.certifiedProfile.certifiedTubes.then((certifiedTubes1)=>{
            this.certifiedTubes = certifiedTubes1;
        });
        this.args.certifiedProfile.certifiedSkills.then((certifiedSkills1)=>{
            this.certifiedSkills = certifiedSkills1;
        });
    }
    get certifiedCompetenceList() {
        const competencesOfCertifiedAreas1 = this.certifiedAreas.toArray().flatMap((certifiedArea1)=>this._buildCertifiedCompetencesOfCertifiedArea(certifiedArea1));
        const [pixCompetences1, nonPixCompetences1] = partition(competencesOfCertifiedAreas1, {
            origin: 'Pix'
        });
        const certifiedCompetencesGroupedByOriginWithNonPixCompetencesFirst1 = [
            ...nonPixCompetences1,
            ...pixCompetences1
        ];
        return certifiedCompetencesGroupedByOriginWithNonPixCompetencesFirst1;
    }
    get difficultyLevels() {
        return Array.from({
            length: ENV.APP.MAX_LEVEL
        }, (_1, i1)=>i1 + 1);
    }
    _buildCertifiedCompetencesOfCertifiedArea(certifiedArea1) {
        return this.certifiedCompetences.filter((certifiedCompetence1)=>certifiedCompetence1.areaId === certifiedArea1.id).map((certifiedCompetence1)=>({
                name: certifiedCompetence1.name,
                certifiedArea: certifiedArea1,
                certifiedTubes: this._buildCertifiedTubeOfCertifiedCompetence(certifiedCompetence1.id),
                origin: certifiedCompetence1.origin
            }));
    }
    _buildCertifiedTubeOfCertifiedCompetence(certifiedCompetenceId1) {
        return this.certifiedTubes.filter((certifiedTube1)=>certifiedTube1.competenceId === certifiedCompetenceId1).map((certifiedTube1)=>({
                name: certifiedTube1.name,
                certifiedSkills: this._buildCertifiedTubeSkillsByLevel(certifiedTube1.id)
            }));
    }
    _buildCertifiedTubeSkillsByLevel(certifiedTubeId1) {
        const tubeSkills1 = this.certifiedSkills.filter((certifiedSkill1)=>certifiedSkill1.tubeId === certifiedTubeId1);
        return this.difficultyLevels.map((_1, index1)=>{
            return tubeSkills1.find((skill1)=>skill1.difficulty === index1 + 1);
        });
    }
    static{
        template(`
    {{pageTitle "Certif " @certifiedProfile.id " Profil | Pix Admin" replace=true}}
    <div class="profile">
      <section class="page-section profile__header">
        <div>
          <p>ID du compte Pix du candidat: {{@certifiedProfile.userId}}</p>
          <p>ID de la certification du candidat: {{@certifiedProfile.id}}</p>
        </div>
        <div class="legend">
          <p>
            <PixIcon @name="doneAll" class="skill-icon skill-icon--tested-in-certif" />
            Acquis proposé en certification
          </p>
          <p>
            <PixIcon @name="check" class="skill-icon skill-icon--check" />
            Acquis validé en direct ou par inférence en positionnement au moment du test de certification
          </p>
        </div>
      </section>
      {{#each this.certifiedCompetenceList as |certifiedCompetence|}}
        <section class="page-section competence">
          <span class="competence__border competence__border--{{certifiedCompetence.certifiedArea.color}}"></span>
          <div>
            <div class="competence__header">
              <h2 class="competence__title">{{certifiedCompetence.name}}</h2>
              <sub class="competence__subtitle">{{certifiedCompetence.certifiedArea.name}}</sub>
            </div>
            <table class="table-admin">
              <thead>
                <tr>
                  <th class="table__column table__column--wide">Sujet</th>
                  {{#each this.difficultyLevels as |difficultyLevel|}}
                    <th class="table__column table__column--small table__column--center">Niveau {{difficultyLevel}}</th>
                  {{/each}}
                </tr>
              </thead>
              <tbody>
                {{#each certifiedCompetence.certifiedTubes as |certifiedTube|}}
                  <tr>
                    <td>{{certifiedTube.name}}</td>
                    {{#each certifiedTube.certifiedSkills as |certifiedSkill|}}
                      <td class="table__column--center skill-column">
                        {{#if certifiedSkill.hasBeenAskedInCertif}}
                          <PixTooltip @position="bottom">
                            <:triggerElement>
                              <PixIcon
                                @name="doneAll"
                                class="skill-icon skill-icon--tested-in-certif"
                                aria-label={{certifiedSkill.name}}
                              />
                            </:triggerElement>
                            <:tooltip>{{concat certifiedSkill.id " " certifiedSkill.name}}</:tooltip>
                          </PixTooltip>
                        {{else if certifiedSkill}}
                          <PixTooltip @position="bottom">
                            <:triggerElement>
                              <PixIcon
                                @name="check"
                                class="skill-icon skill-icon--check"
                                aria-label={{certifiedSkill.name}}
                              />
                            </:triggerElement>
                            <:tooltip>{{concat certifiedSkill.id " " certifiedSkill.name}}</:tooltip>
                          </PixTooltip>
                        {{else}}
                          <PixIcon @name="close" class="skill-icon skill-icon--uncheck" />
                        {{/if}}
                      </td>
                    {{/each}}
                  </tr>
                {{/each}}
              </tbody>
            </table>
          </div>
        </section>
      {{else}}
        <section class="page-section">
          <div class="table__empty">Profil certifié vide.</div>
        </section>
      {{/each}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
