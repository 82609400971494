import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixButtonLink from '@1024pix/pix-ui/components/pix-button-link';
import PixCheckbox from '@1024pix/pix-ui/components/pix-checkbox';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import PixTextarea from '@1024pix/pix-ui/components/pix-textarea';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import Card from '../card';
import Criteria from './badge-form/criteria';
export default class BadgeForm extends Component {
    @service
    pixToast;
    @service
    store;
    @service
    router;
    BASE_URL = 'https://images.pix.fr/badges/';
    badge = {
        key: '',
        altMessage: '',
        message: '',
        title: '',
        isCertifiable: false,
        isAlwaysVisible: false,
        campaignThreshold: null,
        cappedTubesCriteria: []
    };
    imageName = '';
    @action
    updateFormValue(key1, event1) {
        if (key1 === 'imageName') {
            this.imageName = event1.target.value;
        } else {
            this.badge[key1] = event1.target.value;
        }
    }
    @action
    updateFormCheckBoxValue(key1) {
        this.badge[key1] = !this.badge[key1];
    }
    @action
    async submitBadgeCreation(event1) {
        event1.preventDefault();
        const hasCampaignCriteria1 = this.badge.campaignThreshold;
        const hasCappedTubesCriteria1 = this.badge.cappedTubesCriteria.length;
        if (!hasCampaignCriteria1 && !hasCappedTubesCriteria1) {
            return this.pixToast.sendErrorNotification({
                message: "Vous devez sélectionner au moins un critère d'obtention de résultat thématique"
            });
        }
        const hasSelectedCappedTubes1 = this.badge.cappedTubesCriteria[0]?.cappedTubes?.length;
        if (hasCappedTubesCriteria1 && !hasSelectedCappedTubes1) {
            return this.pixToast.sendErrorNotification({
                message: 'Vous devez sélectionner au moins un sujet du profil cible'
            });
        }
        await this._createBadge();
    }
    async _createBadge() {
        try {
            const badgeWithFormattedImageUrl1 = {
                ...this.badge,
                imageUrl: this.BASE_URL + this.imageName
            };
            const badge1 = this.store.createRecord('badge', badgeWithFormattedImageUrl1);
            await badge1.save({
                adapterOptions: {
                    targetProfileId: this.args.targetProfile.id
                }
            });
            await this.args.targetProfile.reload();
            this.pixToast.sendSuccessNotification({
                message: 'Le résultat thématique a été créé.'
            });
            this.router.transitionTo('authenticated.target-profiles.target-profile.insights');
            return badge1;
        } catch (error1) {
            console.error(error1);
            this.pixToast.sendErrorNotification({
                message: `${error1.errors[0].detail}`
            });
        }
    }
    static{
        template(`
    <form class="admin-form admin-form--badge-form" {{on "submit" this.submitBadgeCreation}}>
      <h2 class="badge-form__title">Création d'un résultat thématique</h2>
      <section class="admin-form__content admin-form__content--with-counters">
        <Card class="create-target-profile__card" @title="Remplir des informations sur le résultat thématique">
          <div class="badge-form__text-field">
            <PixInput
              @id="title"
              @value={{this.badge.title}}
              @requiredLabel={{t "common.forms.mandatory"}}
              {{on "change" (fn this.updateFormValue "title")}}
            >
              <:label>Nom du résultat thématique :</:label>
            </PixInput>
          </div>
          <div class="badge-form__text-field">
            <p class="badge-form__information">
              <a
                class="badge-form__information--link"
                href="https://1024pix.github.io/pix-images-list/badges.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Voir la liste des résultats thématiques
              </a>
            </p>
            <PixInput
              @id="image-name"
              @value={{this.imageName}}
              @requiredLabel={{t "common.forms.mandatory"}}
              placeholder="exemple: clea_num.svg"
              {{on "change" (fn this.updateFormValue "imageName")}}
            >
              <:label>Nom de l'image (svg)</:label>
            </PixInput>
          </div>
          <div class="badge-form__text-field">
            <PixInput
              @id="alt-message"
              @value={{this.badge.altMessage}}
              @requiredLabel={{t "common.forms.mandatory"}}
              {{on "change" (fn this.updateFormValue "altMessage")}}
            >
              <:label>Texte alternatif pour l'image :</:label>
            </PixInput>
          </div>
          <div class="badge-form__text-field">
            <PixTextarea
              @id="message"
              @value={{this.badge.message}}
              rows="4"
              {{on "change" (fn this.updateFormValue "message")}}
            >
              <:label>Message :</:label>
            </PixTextarea>
          </div>
          <div class="badge-form__text-field">
            <PixInput
              @id="badge-key"
              maxlength="255"
              @value={{this.badge.key}}
              @requiredLabel={{t "common.forms.mandatory"}}
              {{on "change" (fn this.updateFormValue "key")}}
            >
              <:label>Clé (texte unique , vérifier qu'il n'existe pas) :</:label>
            </PixInput>
          </div>
          <div class="badge-form__check-field">
            <PixCheckbox
              @checked={{this.badge.isCertifiable}}
              {{on "change" (fn this.updateFormCheckBoxValue "isCertifiable")}}
            >
              <:label>Certifiable</:label>
            </PixCheckbox>
          </div>
          <div class="badge-form__check-field">
            <PixCheckbox
              @checked={{this.badge.isAlwaysVisible}}
              {{on "change" (fn this.updateFormCheckBoxValue "isAlwaysVisible")}}
            >
              <:label>Lacunes</:label>
            </PixCheckbox>
          </div>
        </Card>
        <Criteria @badge={{this.badge}} @areas={{@targetProfile.areas}} />
      </section>
      <section class="admin-form__actions">
        <PixButtonLink @variant="secondary" @route="authenticated.target-profiles.target-profile.insights">
          {{t "common.actions.cancel"}}
        </PixButtonLink>
        <PixButton @variant="success" @type="submit">
          Enregistrer le RT
        </PixButton>
      </section>
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
