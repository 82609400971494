import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import AdministrationBlockLayout from '../block-layout';
export default class SwapCampaignCodes extends Component {
    @service
    intl;
    @service
    pixToast;
    @service
    store;
    @tracked
    isLoading;
    @action
    async swapCodes(event1) {
        event1.preventDefault();
        this.isLoading = true;
        const adapter1 = this.store.adapterFor('swap-campaign-code');
        try {
            await adapter1.swap({
                firstCampaignId: this.firstCampaignId,
                secondCampaignId: this.secondCampaignId
            });
            this.pixToast.sendSuccessNotification({
                message: this.intl.t('components.administration.swap-campaign-codes.notifications.success')
            });
        } catch (errorResponse1) {
            const errors1 = errorResponse1.errors;
            if (!errors1) {
                return this.pixToast.sendErrorNotification({
                    message: this.intl.t('common.notifications.generic-error')
                });
            } else {
                const error1 = errors1[0];
                if (error1.code === 'ORGANIZATION_MISMATCH') {
                    return this.pixToast.sendErrorNotification({
                        message: this.intl.t('components.administration.swap-campaign-codes.notifications.error.mismatch-organization')
                    });
                } else if (error1.code === 'UNKNOWN_CAMPAIGN_ID') {
                    this.pixToast.sendErrorNotification({
                        message: this.intl.t('components.administration.swap-campaign-codes.notifications.error.swap-code-error')
                    });
                } else {
                    this.pixToast.sendErrorNotification({
                        message: this.intl.t('common.notifications.generic-error')
                    });
                }
            }
        } finally{
            this.isLoading = false;
        }
    }
    @action
    onChangeCampaign(key1, event1) {
        this[key1] = event1.target.value;
    }
    static{
        template(`
    <AdministrationBlockLayout @title={{t "components.administration.swap-campaign-codes.title"}}>
      <form {{on "submit" this.swapCodes}}>
        <fieldset class="campaigns-swap-code">
          <legend>{{t "components.administration.swap-campaign-codes.description"}}</legend>
          <PixInput @id="firstCampaignId" {{on "change" (fn this.onChangeCampaign "firstCampaignId")}}>
            <:label>{{t "components.administration.swap-campaign-codes.form.firstCampaignId"}}</:label>
          </PixInput>

          <PixInput @id="secondCampaignId" {{on "change" (fn this.onChangeCampaign "secondCampaignId")}}>
            <:label>{{t "components.administration.swap-campaign-codes.form.secondCampaignId"}}</:label>
          </PixInput>
        </fieldset>
        <PixButton @type="submit" @size="small" @isLoading={{this.isLoading}}>
          {{t "components.administration.swap-campaign-codes.form.button"}}
        </PixButton>
      </form>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
