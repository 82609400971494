import { template } from "@ember/template-compiler";
import PixIcon from '@1024pix/pix-ui/components/pix-icon';
import Component from '@glimmer/component';
export default class HabilitationTag extends Component {
    get className() {
        return `habilitation-icon habilitation-icon--${this.args.active ? '' : 'non-'}granted`;
    }
    get icon() {
        const { active: active1 } = this.args;
        return `${active1 ? 'checkCircle' : 'cancel'}`;
    }
    static{
        template(`
    <li aria-label={{@arialabel}}>
      <PixIcon class={{this.className}} @name={{this.icon}} @plainIcon={{true}} />
      {{@label}}
    </li>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
