import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import { concat } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import isInteger from 'lodash/isInteger';
import StageLevelSelect from '../../stages/stage-level-select';
export default class NewStage extends Component {
    @tracked
    thresholdStatus = 'default';
    @tracked
    titleStatus = 'default';
    @tracked
    messageStatus = 'default';
    constructor(...args1){
        super(...args1);
        const { stage: stage1 } = this.args;
        this.threshold = stage1.threshold;
        this.title = stage1.title;
        this.message = stage1.message;
    }
    @action
    checkThresholdValidity(event1) {
        const threshold1 = Number(event1.target.value);
        if (!isInteger(threshold1) || this.args.unavailableThresholds.includes(threshold1) || threshold1 < 0 || threshold1 > 100) {
            this.args.stage.set('threshold', null);
            this.thresholdStatus = 'error';
            return;
        }
        this.thresholdStatus = 'success';
        this.args.stage.set('threshold', threshold1);
    }
    @action
    checkTitleValidity(event1) {
        const title1 = event1.target.value.trim();
        if (!title1) {
            this.titleStatus = 'error';
            this.args.stage.set('title', null);
            return;
        }
        this.titleStatus = 'success';
        this.args.stage.set('title', title1);
    }
    @action
    checkMessageValidity(event1) {
        const message1 = event1.target.value.trim();
        if (!message1) {
            this.messageStatus = 'error';
            this.args.stage.set('message', null);
            return;
        }
        this.messageStatus = 'success';
        this.args.stage.set('message', message1);
    }
    static{
        template(`
    <tr aria-label="Informations sur le palier {{@title}}">
      <td>
        {{#if @stage.isFirstSkill}}
          1er acquis
        {{else}}
          {{#if @stage.isTypeLevel}}
            <StageLevelSelect
              @availableLevels={{@availableLevels}}
              @onChange={{@setLevel}}
              @value={{@stage.levelAsString}}
              class="stages-table__level-select"
              required="true"
              @isDisabled={{@stage.isZeroStage}}
              @label="Niveau du palier"
              @id={{concat "threshold-" @index}}
            />
          {{else}}
            <div class="table__cell--percentage">
              <PixInput
                @id={{concat "threshold-" @index}}
                @errorMessage="Le seuil est invalide"
                @validationStatus={{this.thresholdStatus}}
                @requiredLabel={{t "common.forms.mandatory"}}
                type="number"
                @value={{this.threshold}}
                readonly={{@stage.isZeroStage}}
                @screenReaderOnly={{true}}
                {{on "focusout" this.checkThresholdValidity}}
              >
                <:label>Seuil du palier</:label>
              </PixInput>
              <span>%</span>
            </div>
          {{/if}}
        {{/if}}
      </td>
      <td>
        <PixInput
          @id={{concat "title-" @index}}
          @errorMessage="Le titre est vide"
          @validationStatus={{this.titleStatus}}
          @value={{this.title}}
          @requiredLabel={{t "common.forms.mandatory"}}
          @screenReaderOnly={{true}}
          {{on "focusout" this.checkTitleValidity}}
        >
          <:label>Titre du palier</:label>
        </PixInput>
      </td>
      <td>
        <PixInput
          @id={{concat "message-" @index}}
          @errorMessage="Le message est vide"
          @validationStatus={{this.messageStatus}}
          @requiredLabel={{t "common.forms.mandatory"}}
          @screenReaderOnly={{true}}
          @value={{this.message}}
          {{on "focusout" this.checkMessageValidity}}
        >
          <:label>Message du palier</:label>
        </PixInput>
      </td>
      <td>
        À renseigner ultérieurement
      </td>
      <td>
        À renseigner ultérieurement
      </td>
      <td>
        {{#unless @stage.isZeroStage}}
          <PixButton
            @variant="error"
            @size="small"
            aria-label="Supprimer palier"
            @triggerAction={{@remove}}
            @iconBefore="delete"
          >
            Supprimer
          </PixButton>
        {{/unless}}
      </td>
    </tr>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
