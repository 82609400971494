import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import AdministrationBlockLayout from '../block-layout';
export default class UpdateCampaignCode extends Component {
    @service
    intl;
    @service
    pixToast;
    @service
    store;
    @tracked
    isLoading;
    @action
    async updateCode(event1) {
        event1.preventDefault();
        this.isLoading = true;
        const adapter1 = this.store.adapterFor('update-campaign-code');
        try {
            await adapter1.updateCampaignCode({
                campaignId: this.campaignId,
                campaignCode: this.campaignCode
            });
            this.pixToast.sendSuccessNotification({
                message: this.intl.t('components.administration.update-campaign-code.notifications.success')
            });
        } catch (errorResponse1) {
            const errors1 = errorResponse1.errors;
            if (!errors1) {
                return this.pixToast.sendErrorNotification({
                    message: this.intl.t('common.notifications.generic-error')
                });
            } else {
                const error1 = errors1[0];
                if (error1.code === 'CAMPAIGN_CODE_BAD_FORMAT') {
                    return this.pixToast.sendErrorNotification({
                        message: this.intl.t('components.administration.update-campaign-code.notifications.error.campaign-code-format')
                    });
                } else if (error1.code === 'CAMPAIGN_CODE_NOT_UNIQUE') {
                    return this.pixToast.sendErrorNotification({
                        message: this.intl.t('components.administration.update-campaign-code.notifications.error.unique-code-error')
                    });
                } else if (error1.code === 'UNKNOWN_CAMPAIGN_ID') {
                    this.pixToast.sendErrorNotification({
                        message: this.intl.t('components.administration.update-campaign-code.notifications.error.campaign-id-error')
                    });
                } else {
                    this.pixToast.sendErrorNotification({
                        message: this.intl.t('common.notifications.generic-error')
                    });
                }
            }
        } finally{
            this.isLoading = false;
        }
    }
    @action
    onChangeCampaignId(event1) {
        this.campaignId = event1.target.value;
    }
    @action
    onChangeCampaignCode(event1) {
        this.campaignCode = event1.target.value;
    }
    static{
        template(`
    <AdministrationBlockLayout @title={{t "components.administration.update-campaign-code.title"}}>
      <form {{on "submit" this.updateCode}}>
        <fieldset class="campaign-update-code">
          <legend>{{t "components.administration.update-campaign-code.description"}}</legend>
          <PixInput @id="firstCampaignId" {{on "change" this.onChangeCampaignId}}>
            <:label>{{t "components.administration.update-campaign-code.form.campaignId"}}</:label>
          </PixInput>
          <PixInput @id="secondCampaignId" {{on "change" this.onChangeCampaignCode}}>
            <:label>{{t "components.administration.update-campaign-code.form.campaignCode"}}</:label>
          </PixInput>
        </fieldset>
        <PixButton @type="submit" @size="small" @isLoading={{this.isLoading}}>
          {{t "components.administration.update-campaign-code.form.button"}}
        </PixButton>
      </form>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
