import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixButtonUpload from '@1024pix/pix-ui/components/pix-button-upload';
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import ENV from 'pix-admin/config/environment';
import AdministrationBlockLayout from '../block-layout';
export default class ScoWhitelistConfiguration extends Component {
    @service
    intl;
    @service
    session;
    @service
    pixToast;
    @service
    fileSaver;
    @tracked
    isExportLoading = false;
    @action
    async importScoWhitelist(files1) {
        try {
            const fileContent1 = files1[0];
            const token1 = this.session.data.authenticated.access_token;
            const response1 = await window.fetch(`${ENV.APP.API_HOST}/api/admin/sco-whitelist`, {
                headers: {
                    Authorization: `Bearer ${token1}`,
                    'Content-Type': 'text/csv',
                    Accept: 'application/json'
                },
                method: 'POST',
                body: fileContent1
            });
            if (response1.ok) {
                this.pixToast.sendSuccessNotification({
                    message: this.intl.t('pages.administration.certification.sco-whitelist.import.success')
                });
            } else {
                const responseJson1 = await response1.json();
                const errorKey1 = responseJson1?.errors[0]?.code || 'error';
                this.pixToast.sendErrorNotification({
                    message: this.intl.t(`pages.administration.certification.sco-whitelist.import.${errorKey1}`)
                });
            }
        } catch (error1) {
            this.pixToast.sendErrorNotification({
                message: this.intl.t('pages.administration.certification.sco-whitelist.import.error')
            });
        }
    }
    @action
    async exportWhitelist() {
        try {
            this.isExportLoading = true;
            const url1 = `${ENV.APP.API_HOST}/api/admin/sco-whitelist`;
            const fileName1 = 'sco-whitelist.csv';
            const token1 = this.session.data.authenticated.access_token;
            await this.fileSaver.save({
                url: url1,
                fileName: fileName1,
                token: token1
            });
        } catch (error1) {
            this.pixToast.sendErrorNotification({
                message: this.intl.t('pages.administration.certification.sco-whitelist.export.error')
            });
        } finally{
            this.isExportLoading = false;
        }
    }
    static{
        template(`
    <AdministrationBlockLayout
      @title={{t "pages.administration.certification.sco-whitelist.title"}}
      class="sco-whitelist-configuration"
    >
      <PixNotificationAlert @type="info">{{t
          "pages.administration.certification.sco-whitelist.instructions"
        }}</PixNotificationAlert>

      <div class="sco-whitelist-configuration__actions">
        <PixButton @triggerAction={{this.exportWhitelist}} @isLoading={{this.isExportLoading}}>
          {{t "pages.administration.certification.sco-whitelist.export.button"}}
        </PixButton>
        <PixButtonUpload
          @id="sco-whitelist-file-upload"
          @onChange={{this.importScoWhitelist}}
          @variant="secondary"
          accept=".csv"
        >
          {{t "pages.administration.certification.sco-whitelist.import.button"}}
        </PixButtonUpload>
      </div>
    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
