import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import AdministrationBlockLayout from '../../block-layout';
import Form from './form';
export default class FlashAlgorithmConfiguration extends Component {
    @service
    store;
    @service
    pixToast;
    @tracked
    form = {
        maximumAssessmentLength: this.args.model.maximumAssessmentLength,
        challengesBetweenSameCompetence: this.args.model.challengesBetweenSameCompetence,
        variationPercent: this.args.model.variationPercent,
        limitToOneQuestionPerTube: this.args.model.limitToOneQuestionPerTube,
        enablePassageByAllCompetences: this.args.model.enablePassageByAllCompetences
    };
    @action
    async onCreateFlashAlgorithmConfiguration(event1) {
        event1.preventDefault();
        const adapter1 = this.store.adapterFor('flash-algorithm-configuration');
        try {
            await adapter1.createRecord(this.form);
            this.pixToast.sendSuccessNotification({
                message: 'La configuration a été créée'
            });
        } catch (errorResponse1) {
            this.pixToast.sendErrorNotification({
                message: "La configuration n'a pu être créée"
            });
        }
    }
    @action
    updateNumberValues(event1) {
        this.form = {
            ...this.form,
            [event1.target.id]: event1.target.value
        };
    }
    @action
    updateCheckboxValues(event1) {
        this.form = {
            ...this.form,
            [event1.target.id]: event1.target.checked
        };
    }
    static{
        template(`
    <AdministrationBlockLayout @title={{t "pages.administration.certification.flash-algorithm-configuration.title"}}>
      <Form
        @form={{this.form}}
        @updateNumberValues={{this.updateNumberValues}}
        @updateCheckboxValues={{this.updateCheckboxValues}}
        @onCreateFlashAlgorithmConfiguration={{this.onCreateFlashAlgorithmConfiguration}}
      />

    </AdministrationBlockLayout>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
