import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixCheckbox from '@1024pix/pix-ui/components/pix-checkbox';
import PixNotificationAlert from '@1024pix/pix-ui/components/pix-notification-alert';
import { concat, fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Card from '../../card';
import CampaignCriterion from './campaign-criterion';
import CappedTubesCriterion from './capped-tubes-criterion';
export default class Criteria extends Component {
    @service
    store;
    @tracked
    hasCampaignCriterion = false;
    @tracked
    hasCappedTubesCriteria = false;
    @action
    onHasCampaignCriterionChange(e1) {
        this.hasCampaignCriterion = e1.target.checked;
        if (!this.hasCampaignCriterion) {
            this.args.badge.campaignThreshold = null;
        }
    }
    @action
    onHasCappedTubesCriteriaChange(e1) {
        this.hasCappedTubesCriteria = e1.target.checked;
        if (this.hasCappedTubesCriteria) {
            this.args.badge.cappedTubesCriteria.pushObject({});
        } else {
            this.args.badge.cappedTubesCriteria.clear();
        }
    }
    @action
    onCampaignThresholdChange(e1) {
        this.args.badge.campaignThreshold = e1.target.value;
    }
    @action
    onCappedTubesThresholdChange(criterion1, e1) {
        criterion1.threshold = e1.target.value;
    }
    @action
    onCappedTubesNameChange(criterion1, e1) {
        criterion1.name = e1.target.value;
    }
    @action
    onCappedTubesSelectionChange(criterion1, selection1) {
        criterion1.cappedTubes = selection1;
    }
    @action
    addCappedTubeCriterion() {
        this.args.badge.cappedTubesCriteria.pushObject({});
    }
    @action
    removeCappedTubeCriterion(index1) {
        this.args.badge.cappedTubesCriteria.removeAt(index1);
    }
    static{
        template(`
    <Card @title="Critères d'obtention du résultat thématique">
      <PixNotificationAlert @type="info" @withIcon={{true}}>
        Vous pouvez définir des critères de réussite du résultat thématique
        <strong>sur une liste de sujets ET/OU sur l’ensemble du profil cible</strong>.
        <br />
        <strong>Toutes les conditions devront être remplies</strong>
        pour obtenir le résultat thématique.
      </PixNotificationAlert>
      <div class="badge-form-criteria-choice">
        <p>Définir un critère d'obtention&nbsp;:</p>
        <PixCheckbox
          @id="hasCampaignCriterion"
          @checked={{this.hasCampaignCriterion}}
          {{on "change" this.onHasCampaignCriterionChange}}
        >
          <:label>sur l'ensemble du profil cible</:label>
        </PixCheckbox>
        <PixCheckbox
          @id="hasCappedTubesCriteria"
          @checked={{this.hasCappedTubesCriteria}}
          {{on "change" this.onHasCappedTubesCriteriaChange}}
        >
          <:label>sur une sélection de sujets du profil cible</:label>
        </PixCheckbox>
      </div>
      {{#if this.hasCampaignCriterion}}
        <CampaignCriterion @onThresholdChange={{this.onCampaignThresholdChange}} />
      {{/if}}
      {{#if this.hasCappedTubesCriteria}}
        {{#each @badge.cappedTubesCriteria as |criterion index|}}
          <CappedTubesCriterion
            @id={{concat "cappedTubeCriterion" index}}
            @areas={{@areas}}
            @onThresholdChange={{fn this.onCappedTubesThresholdChange criterion}}
            @onNameChange={{fn this.onCappedTubesNameChange criterion}}
            @onTubesSelectionChange={{fn this.onCappedTubesSelectionChange criterion}}
            @remove={{fn this.removeCappedTubeCriterion index}}
          />
        {{/each}}
        <PixButton
          class="badge-form-criterion__add"
          @variant="primary"
          @size="small"
          @triggerAction={{this.addCappedTubeCriterion}}
          @iconBefore="add"
        >
          Ajouter une nouvelle sélection de sujets
        </PixButton>
      {{/if}}
    </Card>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
