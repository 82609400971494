import { template } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixInput from '@1024pix/pix-ui/components/pix-input';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import pick from 'ember-composable-helpers/helpers/pick';
import { t } from 'ember-intl';
import set from 'ember-set-helper/helpers/set';
import uniq from 'lodash/uniq';
import ListItems from '../organizations/list-items';
export default class Organizations extends Component {
    @service
    store;
    @service
    pixToast;
    @service
    router;
    @service
    currentUser;
    @service
    intl;
    @tracked
    organizationsToAttach = '';
    @tracked
    existingTargetProfile = '';
    get isSuperAdminOrMetier() {
        return this.currentUser.adminMember.isSuperAdmin || this.currentUser.adminMember.isMetier;
    }
    get isDisabledAttachOrganizationsFromExistingTargetProfile() {
        return this.existingTargetProfile === '';
    }
    get isDisabledAttachOrganizations() {
        return this.organizationsToAttach === '';
    }
    @action
    async attachOrganizations(e1) {
        e1.preventDefault();
        if (this.isDisabledAttachOrganizations) return;
        const targetProfile1 = this.args.targetProfile;
        const adapter1 = this.store.adapterFor('target-profile');
        try {
            const response1 = await adapter1.attachOrganizations({
                organizationIds: this._getUniqueOrganizations(),
                targetProfileId: targetProfile1.id
            });
            const { 'attached-ids': attachedIds1, 'duplicated-ids': duplicatedIds1 } = response1.data.attributes;
            this.organizationsToAttach = '';
            const hasInsertedOrganizations1 = attachedIds1.length > 0;
            const hasDuplicatedOrgnizations1 = duplicatedIds1.length > 0;
            const message1 = [];
            if (hasInsertedOrganizations1) {
                message1.push('Organisation(s) rattaché(es) avec succès.');
            }
            if (hasInsertedOrganizations1 && hasDuplicatedOrgnizations1) {
                message1.push('<br/>');
            }
            if (hasDuplicatedOrgnizations1) {
                message1.push(`Le(s) organisation(s) suivantes étai(en)t déjà rattachée(s) à ce profil cible : ${duplicatedIds1.join(', ')}`);
            }
            await this.pixToast.sendSuccessNotification({
                message: message1.join('')
            });
            return this.router.replaceWith('authenticated.target-profiles.target-profile.organizations');
        } catch (responseError1) {
            this._handleResponseError(responseError1);
        }
    }
    @action
    async organizationsFromExistingTargetProfileToAttach(e1) {
        e1.preventDefault();
        if (this.isDisabledAttachOrganizationsFromExistingTargetProfile) return;
        const targetProfile1 = this.args.targetProfile;
        const adapter1 = this.store.adapterFor('target-profile');
        try {
            await adapter1.attachOrganizationsFromExistingTargetProfile({
                targetProfileId: targetProfile1.id,
                targetProfileIdToCopy: this.existingTargetProfile
            });
            this.existingTargetProfile = '';
            await this.pixToast.sendSuccessNotification({
                message: 'Organisation(s) rattaché(es) avec succès.'
            });
            return this.router.replaceWith('authenticated.target-profiles.target-profile.organizations');
        } catch (responseError1) {
            this._handleResponseError(responseError1);
        }
    }
    _handleResponseError({ errors: errors1 }) {
        const genericErrorMessage1 = this.intl.t('common.notifications.generic-error');
        if (!errors1) {
            return this.pixToast.sendErrorNotification({
                message: genericErrorMessage1
            });
        }
        errors1.forEach((error1)=>{
            if ([
                '404',
                '412'
            ].includes(error1.status)) {
                return this.pixToast.sendErrorNotification({
                    message: error1.detail
                });
            }
            return this.pixToast.sendErrorNotification({
                message: genericErrorMessage1
            });
        });
    }
    _getUniqueOrganizations() {
        const targetProfileIds1 = this.organizationsToAttach.split(',').map((targetProfileId1)=>parseInt(targetProfileId1.trim()));
        return uniq(targetProfileIds1);
    }
    static{
        template(`
    <section class="page-section target-profile-organizations">
      <div class="organization__forms-section">
        <form class="organization__form" {{on "submit" this.attachOrganizations}}>
          <label for="attach-organizations">Rattacher une ou plusieurs organisation(s)</label>
          <div class="organization__sub-form">
            <PixInput
              id="attach-organizations"
              @value={{this.organizationsToAttach}}
              class="form-field__text form-control"
              placeholder="1, 2"
              aria-describedby="attach-organizations-info"
              {{on "input" (pick "target.value" (set this "organizationsToAttach"))}}
            />
            <p id="attach-organizations-info" hidden>Ids des organisations, séparés par une virgule</p>
            <PixButton
              @type="submit"
              @size="small"
              aria-label="Valider le rattachement"
              @isDisabled={{this.isDisabledAttachOrganizations}}
            >
              {{t "common.actions.validate"}}
            </PixButton>
          </div>
        </form>

        <form class="organization__form" {{on "submit" this.organizationsFromExistingTargetProfileToAttach}}>
          <label for="attach-organizations-from-existing-target-profile">Rattacher les organisations d'un profil cible
            existant</label>
          <div class="organization__sub-form">
            <PixInput
              id="attach-organizations-from-existing-target-profile"
              @value={{this.existingTargetProfile}}
              class="form-field__text form-control"
              placeholder="1135"
              aria-describedby="attach-organizations-from-existing-target-profile-info"
              {{on "input" (pick "target.value" (set this "existingTargetProfile"))}}
            />
            <p id="attach-organizations-from-existing-target-profile-info" hidden>Id du profil cible existant</p>
            <PixButton
              @type="submit"
              @size="small"
              aria-label="Valider le rattachement à partir de ce profil cible"
              @isDisabled={{this.isDisabledAttachOrganizationsFromExistingTargetProfile}}
            >
              {{t "common.actions.validate"}}
            </PixButton>
          </div>
        </form>
      </div>
    </section>
    <section class="page-section">
      <header class="page-section__header">
        <h2 class="page-section__title">Organisations</h2>
      </header>

      <ListItems
        @organizations={{@organizations}}
        @id={{@id}}
        @name={{@name}}
        @type={{@type}}
        @externalId={{@externalId}}
        @triggerFiltering={{@triggerFiltering}}
        @goToOrganizationPage={{@goToOrganizationPage}}
        @detachOrganizations={{@detachOrganizations}}
        @targetProfileName={{@targetProfile.name}}
        @showDetachColumn={{this.isSuperAdminOrMetier}}
      />
    </section>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
